import { BANNER_EDIT_FAIL, BANNER_EDIT_REQUEST, BANNER_EDIT_SUCCESS, BANNER_UPDATE_FAIL, BANNER_UPDATE_REQUEST, BANNER_UPDATE_SUCCESS, CARD_EDIT_FAIL, CARD_EDIT_REQUEST, CARD_EDIT_SUCCESS, CARD_UPDATE_FAIL, CARD_UPDATE_REQUEST, CARD_UPDATE_SUCCESS, CARDS_LIST_FAIL, CARDS_LIST_REQUEST, CARDS_LIST_SUCCESS } from '../Constants/CustomPageConstants';
import axios from 'axios';
import { logout } from './userActions';
import { URL } from '../Url';

export const listCards = () => async (dispatch) => {
	try {
		dispatch({ type: CARDS_LIST_REQUEST });

		const { data } = await axios.get(`${URL}/api/cards/all`);

		dispatch({ type: CARDS_LIST_SUCCESS, payload: data });
	} catch (error: any) {
		const message = error.message && error.response.data.message ? error.response.data.message : error.message;
		if (message === 'Not authorized, token failed') {
			dispatch(logout() as any);
		}
		dispatch({
			type: CARDS_LIST_FAIL,
			payload: message
		});
	}
};

// EDIT CARD
export const editCard = (id) => async (dispatch) => {
	try {
		dispatch({ type: CARD_EDIT_REQUEST });
		const { data } = await axios.get(`${URL}/api/cards/${id}`);
		dispatch({ type: CARD_EDIT_SUCCESS, payload: data });
	} catch (error: any) {
		const message = error.message && error.response.data.message ? error.response.data.message : error.message;
		if (message === 'Not authorized, token failed') {
			dispatch(logout() as any);
		}
		dispatch({
			type: CARD_EDIT_FAIL,
			payload: message
		});
	}
};

// UPDATE CARD
export const updateCard = (card) => async (dispatch, getState) => {
	try {
		dispatch({ type: CARD_UPDATE_REQUEST });

		const {
			userLogin: { userInfo }
		} = getState();

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`
			}
		};

		const { data } = await axios.put(`${URL}/api/cards/${card._id}`, card, config);

		dispatch({ type: CARD_UPDATE_SUCCESS, payload: data });
		dispatch({ type: CARD_EDIT_SUCCESS, payload: data });
	} catch (error: any) {
		const message = error.message && error.response.data.message ? error.response.data.message : error.message;
		if (message === 'Not authorized, token failed') {
			dispatch(logout() as any);
		}
		dispatch({
			type: CARD_UPDATE_FAIL,
			payload: message
		});
	}
};

// EDIT BANNER
export const editBanner = (id) => async (dispatch) => {
	try {
		dispatch({ type: BANNER_EDIT_REQUEST });

		const { data } = await axios.get(`${URL}/api/banners/${id}`);

		dispatch({ type: BANNER_EDIT_SUCCESS, payload: data });
	} catch (error: any) {
		const message = error.message && error.response.data.message ? error.response.data.message : error.message;
		if (message === 'Not authorized, token failed') {
			dispatch(logout() as any);
		}
		dispatch({
			type: BANNER_EDIT_FAIL,
			payload: message
		});
	}
};

// UPDATE PRODUCT
export const updateBanner = (banner) => async (dispatch, getState) => {
	try {
		dispatch({ type: BANNER_UPDATE_REQUEST });

		const {
			userLogin: { userInfo }
		} = getState();

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`
			}
		};

		const { data } = await axios.put(`${URL}/api/banners/${banner._id}`, banner, config);

		dispatch({ type: BANNER_UPDATE_SUCCESS, payload: data });
		dispatch({ type: BANNER_EDIT_SUCCESS, payload: data });
	} catch (error: any) {
		const message = error.message && error.response.data.message ? error.response.data.message : error.message;
		if (message === 'Not authorized, token failed') {
			dispatch(logout() as any);
		}
		dispatch({
			type: BANNER_UPDATE_FAIL,
			payload: message
		});
	}
};