import axios from 'axios';
import { logout } from './userActions';
import { URL } from '../Url';
import { UPLOAD_FAIL, UPLOAD_REQUEST, UPLOAD_SUCCESS } from '../Constants/UploadConstants';

// SUBIR IMAGEN
export const upload = (file, site: string) => async (dispatch, getState) => {
	try {
		dispatch({ type: UPLOAD_REQUEST });

		const {
			userLogin: { userInfo }
		} = getState();

		// Creamos un objeto FormData para enviar la imagen al servidor
		const formData = new FormData();
		// 'image' debe coincidir con el campo que envías desde el frontend
		formData.append('image', file);
		// Enviamos el site para poder crear la ruta en R2
		formData.append('site', site);

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${userInfo.token}`
			}
		};

		// Hacemos la petición al servidor para subir la imagen a R2 y obtener la URL
		const { data } = await axios.post(`${URL}/api/upload`, formData, config);

		dispatch({ type: UPLOAD_SUCCESS, payload: data.imageUrl }); // Guardamos la URL devuelta en el payload
		// Devolvemos la URL para poder guardarla en el estado del componente
		return data.imageUrl;
	} catch (error: any) {
		const message = error.response && error.response.data.message ? error.response.data.message : error.message;
		if (message === 'Not authorized, token failed') {
			dispatch(logout());
		}
		dispatch({
			type: UPLOAD_FAIL,
			payload: message
		});
		throw new Error(message); // Lanzamos el error para manejarlo en el componente si es necesario
	}
};
