export const CARDS_LIST_REQUEST = "CARDS_LIST_REQUEST";
export const CARDS_LIST_SUCCESS = "CARDS_LIST_SUCCESS";
export const CARDS_LIST_FAIL = "CARDS_LIST_FAIL";

export const CARD_EDIT_REQUEST = 'CARD_EDIT_REQUEST';
export const CARD_EDIT_SUCCESS = 'CARD_EDIT_SUCCESS';
export const CARD_EDIT_FAIL = 'CARD_EDIT_FAIL';

export const CARD_UPDATE_REQUEST = "CARD_UPDATE_REQUEST";
export const CARD_UPDATE_SUCCESS = "CARD_UPDATE_SUCCESS";
export const CARD_UPDATE_FAIL = "CARD_UPDATE_FAIL";
export const CARD_UPDATE_RESET = 'CARD_UPDATE_RESET';

export const BANNER_UPDATE_REQUEST = "BANNER_UPDATE_REQUEST";
export const BANNER_UPDATE_SUCCESS = "BANNER_UPDATE_SUCCESS";
export const BANNER_UPDATE_FAIL = "BANNER_UPDATE_FAIL";
export const BANNER_UPDATE_RESET = 'BANNER_UPDATE_RESET';

export const BANNER_EDIT_REQUEST = 'BANNER_EDIT_REQUEST';
export const BANNER_EDIT_SUCCESS = 'BANNER_EDIT_SUCCESS';
export const BANNER_EDIT_FAIL = 'BANNER_EDIT_FAIL';