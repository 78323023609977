import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PRODUCT_CREATE_RESET } from '../../Redux/Constants/ProductConstants';
import { createProduct } from '../../Redux/Actions/ProductActions';
import Message from '../LoadingError/Error';
import Loading from '../LoadingError/Loading';
import Toast from '../LoadingError/Toast';
import { listCategories } from '../../Redux/Actions/CategoryActions';
import { upload } from '../../Redux/Actions/UploadActions';

const ToastObjects = {
	pauseOnFocusLoss: false,
	draggable: false,
	pauseOnHover: false,
	autoClose: 2000 // miliseconds
};
const AddProductMain = () => {
	const [type, setType] = useState('');
	const [liga, setLiga] = useState('');
	const [name, setName] = useState('');
	const [price, setPrice] = useState(0);
	const [priceTag, setPriceTag] = useState('');
	const [priceDorsal, setPriceDorsal] = useState('');
	const [image, setImage] = useState<string | unknown>(null);
	const [countInStock, setCountInStock] = useState(0);
	const [sizes, setSize] = useState<string[]>([]);
	const [sales, setSales] = useState(0);
	const [inputSize, setInputSize] = useState('');
	const [description, setDescription] = useState('');
	const [errorImg, setError] = useState('');

	const dispatch = useDispatch();

	const productCreate = useSelector((state: any) => state.productCreate);
	const { loading, error, product } = productCreate;

	const categoryList = useSelector((state: any) => state.categoryList);
	const { loading: loadingCategory, error: errorCategory, categories } = categoryList;

	const addSize = () => {
		if (inputSize) {
			setSize((prevSizes) => [...prevSizes, inputSize]);
			setInputSize('');
		}
  };
  
  const removeSize = (sizeToRemove: string) => {
		setSize((prevSizes) => prevSizes.filter((sizes) => sizes !== sizeToRemove));
	};

	const handleUploadImage = async (e) => {
		const file = e.target.files[0];
		const maxSize = 5 * 1024 * 1024; // 5MB

		if (file.size > maxSize) {
			setError('El archivo es demasiado grande. Debe ser menor de 5MB.');
			return; // Detenemos la ejecución si el archivo es demasiado grande
		}

		setError(''); // Limpiamos cualquier error previo
		if (file) {
			try {
				const imageUrl = await dispatch(upload(file, "products") as any);
				setImage(imageUrl); // Actualizamos el estado con la URL de la imagen
				console.log('Imagen subida:', imageUrl);
			} catch (error) {
				console.error('Error subiendo la imagen:', error);
			}
		}
	};

	useEffect(() => {
		dispatch(listCategories() as any);
		if (product) {
			toast.success('Añadir producto', ToastObjects);
			dispatch({ type: PRODUCT_CREATE_RESET });
			setType('');
			setLiga('');
			setName('');
			setDescription('');
			setCountInStock(0);
			setSize([]);
			setImage('');
			setPrice(0);
			setPriceTag(0);
			setPriceDorsal(0);
			setSales(0);
		}
	}, [product, dispatch]);

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(createProduct(type, liga, name, price, description, image, countInStock, sizes, sales, priceTag, priceDorsal) as any);
	};
	const leagueCategories = categories.filter((category) => category.type === 'Liga');
	return (
		<>
			<Toast />
			<section className="content-main" style={{ maxWidth: '1200px' }}>
				<form onSubmit={submitHandler}>
					<div className="content-header">
						<Link to="/products" className="btn btn-danger text-white">
							Ir a productos
						</Link>
						<h2 className="content-title">Agregar producto</h2>
						<div>
							<button type="submit" className="btn btn-primary">
								Publica ahora
							</button>
						</div>
					</div>

					<div className="row">
						<div className="card shadow-sm">
							<div className="card-body">
								{error && <Message variant="alert-danger">{error}</Message>}
								{loading && <Loading />}
								<div className="mb-4">
									<label className="form-label">Tipo de producto</label>
									<select value={type} className="form-select" onChange={(e) => setType(e.target.value)}>
										<option value="Ropa">Ropa</option>
										<option value="Pack">Pack</option>
									</select>
								</div>
								{type === 'Ropa' && (
									<>
										<div className="mb-4">
											<label className="form-label">Tipo de liga</label>
											<select value={liga} className="form-select" onChange={(e) => setLiga(e.target.value)}>
												{leagueCategories.map((category) => (
													<option key={category._id} value={category.name}>
														{category.name}
													</option>
												))}
											</select>
										</div>
										<div className="mb-4">
											<label htmlFor="product_title" className="form-label">
												Titulo del producto
											</label>
											<input type="text" placeholder="Type here" className="form-control" id="product_title" required value={name} onChange={(e) => setName(e.target.value)} />
										</div>
										<div className="mb-4">
											<label htmlFor="product_price" className="form-label">
												Precio
											</label>
											<input type="number" placeholder="0" className="form-control" id="product_price" required value={price} onChange={(e) => setPrice(Number(e.target.value))} />
										</div>
										<div className="mb-4">
											<label htmlFor="product_price_tag" className="form-label">
												Precio Tag
											</label>
											<input type="number" placeholder="0" className="form-control" id="product_price_tag" required value={priceTag} onChange={(e) => setPriceTag(Number(e.target.value))} />
										</div>
										<div className="mb-4">
											<label htmlFor="product_price_dorsal" className="form-label">
												Precio Dorsal
											</label>
											<input type="number" placeholder="0" className="form-control" id="product_price_dorsal" required value={priceDorsal} onChange={(e) => setPriceDorsal(Number(e.target.value))} />
										</div>
										<div className="mb-4">
											<label htmlFor="product_stock" className="form-label">
												Numero de Stock
											</label>
											<input type="number" placeholder="0" className="form-control" id="product_stock" required value={countInStock} onChange={(e) => setCountInStock(Number(e.target.value))} />
										</div>
										<div className="mb-4">
											<label htmlFor="product_sales" className="form-label">
												Numero de Ventas
											</label>
											<input type="number" placeholder="0" className="form-control" id="product_sales" required value={sales} onChange={(e) => setSales(Number(e.target.value))} />
										</div>
										<div className="d-flex flex-column gap-2">
											<label htmlFor="product_size" className="form-label">
												Tallas
											</label>
											<input type="text" placeholder="Type here" className="form-control" id="product_size" value={inputSize} onChange={(e) => setInputSize(e.target.value)} />
											<button className="btn btn-outline-primary" type="button" onClick={addSize}>
												Agregar talla
											</button>
											<div className="d-flex flex-wrap gap-2">
												{sizes.map((s, index) => (
													<span className="badge rounded-pill text-bg-primary" data-bs-theme="dark" key={index}>
														{s}
														<button type="button" className="white btn-close ms-2" onClick={() => removeSize(s)}></button>
													</span>
												))}
											</div>
										</div>
										<div className="mb-4">
											<label className="form-label">Descripción</label>
											<textarea placeholder="Type here" className="form-control" rows={7} required value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
										</div>
										<div className="mb-4">
											<label className="form-label">Imagen</label>
											<input className="form-control" type="file" accept="image/*" onChange={handleUploadImage} />
										</div>
									</>
								)}
								{type === 'Pack' && (
									<>
										<div className="mb-4">
											<label className="form-label">Tipo de liga</label>
											<select value={liga} className="form-select" onChange={(e) => setLiga(e.target.value)}>
												{leagueCategories.map((category) => (
													<option key={category._id} value={category.name}>
														{category.name}
													</option>
												))}
											</select>
										</div>
										<div className="mb-4">
											<label htmlFor="product_title" className="form-label">
												Titulo del producto
											</label>
											<input type="text" placeholder="Type here" className="form-control" id="product_title" required value={name} onChange={(e) => setName(e.target.value)} />
										</div>
										<div className="mb-4">
											<label htmlFor="product_price" className="form-label">
												Precio
											</label>
											<input type="number" placeholder="0" className="form-control" id="product_price" required value={price} onChange={(e) => setPrice(Number(e.target.value))} />
										</div>
										<div className="mb-4">
											<label htmlFor="product_stock" className="form-label">
												Numero de Stock
											</label>
											<input type="number" placeholder="0" className="form-control" id="product_stock" required value={countInStock} onChange={(e) => setCountInStock(Number(e.target.value))} />
										</div>
										<div className="mb-4">
											<label className="form-label">Descripción</label>
											<textarea placeholder="Type here" className="form-control" rows={7} required value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
										</div>
										<div className="mb-4">
											<label className="form-label">Imagen</label>
											<input className="form-control" type="file" accept="image/*" onChange={handleUploadImage} />
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</form>
			</section>
		</>
	);
};

export default AddProductMain;
