import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../LoadingError/Loading';
import Message from '../LoadingError/Error';
import { editBanner, listCards, updateBanner } from '../../Redux/Actions/CustomPageActions';
import { BANNER_UPDATE_RESET } from '../../Redux/Constants/CustomPageConstants';
import { toast } from 'react-toastify';
import { upload } from '../../Redux/Actions/UploadActions';

const ToastObjects = {
	pauseOnFocusLoss: false,
	draggable: false,
	pauseOnHover: false,
	autoClose: 2000
};

const CustomPageComponent = () => {
	const bannerId = '66a3b88222ee65d6c1b169ee';
	const [text, setText] = useState('');
	const [color, setColor] = useState('');
	const [image, setImage] = useState('');
	const [error, setError] = useState('');
	const dispatch = useDispatch();

	const bannerEdit = useSelector((state: any) => state.bannerEdit);
	const { loading: loadingEditBanner, error: errorEditBanner, banner } = bannerEdit;

	const bannerUpdate = useSelector((state: any) => state.bannerUpdate);
	const { loading: loadingUpdateBanner, error: errorUpdateBanner, success: successUpdateBanner } = bannerUpdate;


	useEffect(() => {
		dispatch(listCards() as any);
		if (successUpdateBanner) {
			dispatch({ type: BANNER_UPDATE_RESET });
			toast.success('Banner actualizado', ToastObjects);
		} else {
			if (!banner.name || banner._id !== bannerId) {
				dispatch(editBanner(bannerId) as any);
			} else {
				setText(banner.name);
				setColor(banner.color);
				setImage(banner.image);
			}
		}
	}, [banner, dispatch, bannerId, successUpdateBanner]);

		const handleColorChange = (e) => {
			const hexColor = e.target.value;
			if (/^#[0-9A-F]{6}$/i.test(hexColor)) {
				setColor(hexColor);
			}
		};

	const handleUploadImage = async (e) => {
		const file = e.target.files[0];
		const maxSize = 5 * 1024 * 1024; // 5MB

		if (file.size > maxSize) {
			setError('El archivo es demasiado grande. Debe ser menor de 5MB.');
			return; // Detenemos la ejecución si el archivo es demasiado grande
		}

		setError(''); // Limpiamos cualquier error previo
		if (file) {
			try {
				const imageUrl = await dispatch(upload(file, "banners") as any);
				setImage(imageUrl); // Actualizamos el estado con la URL de la imagen
				console.log('Imagen subida:', imageUrl);
			} catch (error) {
				console.error('Error subiendo la imagen:', error);
			}
		}
	};

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(
			updateBanner({
				_id: bannerId,
				name: text,
				color: color,
				image: image
			}) as any
		);
		console.log(bannerId, text, color, image);
	};
	return (
		<section className="content-main">
			<form onSubmit={submitHandler}>
				<div className="content-header">
					<h2 className="content-title">Personaliza pagina principal</h2>
					<div>
						<button type="submit" className="btn btn-primary">
							Publica ahora
						</button>
					</div>
				</div>

				<div className="card mb-4">
					<div className="card-body">
						{errorUpdateBanner && <Message variant="alert-danger">{errorUpdateBanner}</Message>}
						{loadingUpdateBanner && <Loading />}
						{loadingEditBanner ? (
							<Loading />
						) : errorEditBanner ? (
							<Message variant="alert-danger">{errorEditBanner}</Message>
						) : (
							<>
								<div className="mb-4">
									<div className="d-flex justify-content-center gap-2 mb-2">
										<label htmlFor="color">Color:</label>
										<input type="color" value={color} onChange={handleColorChange} />
									</div>
									<div style={{ backgroundColor: color }}>
										<div className="container">
											<div className="row">
												<div className="d-flex align-items-center justify-content-center">
													<input style={{ color: 'white' }} placeholder="Camisetas al 50% de la seleccion española" className="d-flex justify-content-center text-center bg-transparent border-0 w-100" id="text" required value={text} onChange={(e) => setText(e.target.value)} />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="container-fluid py-2">
									<input id={`file-${banner._id}`} className="w-100 h-100" type="file" accept="image/*" onChange={handleUploadImage} style={{ display: 'none' }} />
									{error && <Message variant="alert-danger">{error}</Message>}
									<label htmlFor={`file-${banner._id}`} className="w-100 h-100">
										<img className="w-100 h-100 rounded" style={{ cursor: 'pointer' }} src={image} alt={text} />
									</label>
								</div>
							</>
						)}
					</div>
				</div>
			</form>
		</section>
	);
};

export default CustomPageComponent;
